import { nodes } from "./getRpcUrl";

export const BASE_POLYGON_SCAN_URLS = {
  Mainnet: "https://polygonscan.com/",
  Testnet: "https://mumbai.polygonscan.com/",
};

export const BASE_POLYGON_SCAN_URL = BASE_POLYGON_SCAN_URLS["Testnet"];

const CHAINS = {
  bsc: {
    chainName: "Binance Smart Chain",
    chainId: 56,
    currency: "BNB",
    rpcUrls: "https://bsc-dataseed1.binance.org",
    blockExplorerUrls: "https://bscscan.com/",
  },
  polygon: {
    chainName: "Polygon",
    chainId: 137,
    currency: "MATIC",
    rpcUrls: "https://polygon-rpc.com",
    blockExplorerUrls: "https://polygonscan.com/",
  },
  avalanche: {
    chainName: "Avalanche",
    chainId: 43114,
    currency: "AVAX",
    rpcUrls: "https://api.avax.network/ext/bc/C/rpc",
    blockExplorerUrls: "https://snowtrace.io",
  },
  optimism: {
    chainName: "Optimism",
    chainId: 10,
    currency: "ETH",
    rpcUrls: "https://mainnet.optimism.io",
    blockExplorerUrls: "https://snowtrace.io",
  },
  fantom: {
    chainName: "Fantom",
    chainId: 250,
    currency: "FTM",
    rpcUrls: "https://rpc.ftm.tools",
    blockExplorerUrls: "https://ftmscan.com",
  },
};

export const registerToken = async (
  tokenAddress,
  tokenSymbol,
  tokenDecimals,
  tokenImage
) => {
  const tokenAdded = await window.ethereum.request({
    method: "wallet_watchAsset",
    params: {
      type: "ERC20",
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: tokenImage,
      },
    },
  });

  return tokenAdded;
};

export const setupNetwork = async (chain = "bsc") => {
  const provider = window.ethereum;
  if (provider) {
    const { chainName, chainId, currency, rpcUrls, blockExplorerUrls } =
      CHAINS[chain];
    console.log(chainName, chainId, currency, rpcUrls, blockExplorerUrls);
    try {
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x${chainId.toString(16)}`,
            chainName,
            nativeCurrency: {
              name: currency,
              symbol: currency,
              decimals: 18,
            },
            rpcUrls: [rpcUrls],
            blockExplorerUrls: [blockExplorerUrls],
          },
        ],
      });
      return true;
    } catch (error) {
      console.error("Failed to setup the network in Metamask:", error);
      return false;
    }
  } else {
    console.error(
      "Can't setup the BSC network on metamask because window.ethereum is undefined"
    );
    return false;
  }
};
