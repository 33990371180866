import { Icon } from "@iconify/react";
// routes

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Claim",
    path: "/claim",
  },
  {
    title: "Map",
    path: "/map",
  },
  {
    title: "Whitepaper",
    path: "#whitepaper",
  },
  {
    title: "Blog",
    path: "/blog",
  },
  {
    title: "Faq",
    path: "/faq",
  },
  // { title: "Return", path: "/#return" },
  // { title: "Become a Winker", path: "/#winker" },
  // { title: "FAQ", path: "/#faq" },
  // { title: "Docs", path: "https://docs.winks.finance/" }
];

export default menuConfig;
