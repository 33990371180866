import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import {
  Container,
  Box,
  Stack,
  TextField,
  Button,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useICOContract } from "hooks/useContract";
import { getErrorMessage } from "utils/getErrorMessage";
import { parseEther } from "ethers/lib/utils";
import { useSnackbar } from "notistack";
import { ethers } from "ethers";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import axios from "axios";
import { ToText } from "utils/medium";
import Moment from "react-moment";

export default function Homepage() {
  const [expanded, setExpanded] = useState("panel1");
  const [articles, setArticles] = useState([]);

  useEffect(async () => {
    const res = await axios.get(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@infinityglobalapp"
    );
    setArticles(res.data.items);
    console.log(res.data.items);
  }, []);

  return (
    <Box>
      <Stack
        alignItems="flex-start"
        justifyContent="center"
        spacing={5}
        sx={{ width: 1, mt: 20 }}
      >
        <Container maxWidth="lg">
          <Stack justifyContent="flex-start" spacing={5} mb={5}>
            <Typography variant="h2">Blog</Typography>
          </Stack>
          <Stack spacing={4}>
            {articles.length > 0 &&
              articles.map((item, index) => (
                <Stack direction="row" spacing={2}>
                  <Box
                    sx={{
                      borderRadius: 1,
                      width: 200,
                      height: 200,
                      backgroundImage: `url(${item.thumbnail})`,
                      backgroundPosition: "50%",
                      backgroundSize: "cover",
                      alignSelf: "center",
                    }}
                  />
                  <Stack
                    flex={1}
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <Typography variant="h4">{item.title}</Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography fontSize={20} color="#d3d4df">
                        <Moment format="MM-DD">{item.pubDate}</Moment>
                      </Typography>
                      <CalendarTodayIcon />
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: 20,
                        color: "#d3d4df",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                    >
                      {ToText(item.description.substring(0, 500))}
                    </Typography>
                    <Button
                      variant="contained"
                      href={item.link}
                      target="_blank"
                    >
                      LEARN MORE
                    </Button>
                  </Stack>
                </Stack>
              ))}
          </Stack>
        </Container>
      </Stack>
    </Box>
  );
}
