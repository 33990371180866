import { useEffect } from "react";
import Router from "./router";
import ThemeConfig from "theme";
import Settings from "./components/settings";
import { switchNetwork } from "utils/wallet";
import useEagerConnect from "hooks/useEagerConnect";
import ThemeLocalization from "components/ThemeLocalization";

export default function App() {
  // switchNetwork();
  useEagerConnect();

  return (
    <ThemeConfig>
      <ThemeLocalization>
        <Settings />
        <Router />
      </ThemeLocalization>
    </ThemeConfig>
    
  );
}

/* import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
//import Model from './Model';
import { Model } from 'Model';

export default function App() {
   return (
      <Canvas
         camera={{ position: [2, 0, 12.25], fov: 15 }}
         style={{
            backgroundColor: '#111a21',
            width: '100vw',
            height: '100vh',
         }}
      >
         <ambientLight intensity={1.25} />
         <ambientLight intensity={0.1} />
         <directionalLight intensity={0.4} />
         <Suspense fallback={null}>
            <Model position={[0.025, -0.9, 1]} />
         </Suspense>
         <OrbitControls />
      </Canvas>
   );
} */