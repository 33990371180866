import {
  Stack,
  Box,
  Typography,
  Container,
  IconButton,
  Button,
  InputBase,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { Icon } from "@iconify/react";
import medium from "@iconify/icons-cib/medium";
import youtube from "@iconify/icons-cib/youtube";
import facebook from "@iconify/icons-cib/facebook";
import skype from "@iconify/icons-cib/skype";
import github from "@iconify/icons-cib/github";
import twitter from "@iconify/icons-cib/twitter";
import telegram from "@iconify/icons-cib/telegram";
import discord from "@iconify/icons-cib/discord";
import linkedin from "@iconify/icons-cib/linkedin";
import instagram from "@iconify/icons-cib/instagram";
import menuConfig from "./MenuConfig";
import Logo from "components/Logo";
import { Link as ScrollLink } from "react-scroll";

const LinkStyle = styled(Link)(({ theme }) => ({
  fontSize: 20,
  cursor: "pointer",
  color: "#6b74a2",
  fontWeight: 400,
  textDecoration: "none",
  transition: "all 0.3s",
  "&:hover": {
    textDecoration: "underline",
  },
}));

export default function MainFooter() {
  return (
    <Stack
      sx={{
        mt: 10,
        py: 10,
      }}
    >
      <Container maxWidth="lg">
        <Stack direction="row" justifyContent="space-between">
          <Stack alignItems="center">
            <Logo sx={{ width: 200 }} />
            <Typography variant="h5" color="#6b74a2">
              Infinity Global@2022
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h4">OUTLINE</Typography>
            <LinkStyle>Referral Contest</LinkStyle>
            <LinkStyle>NFT Airdrop</LinkStyle>
            <LinkStyle>Claim</LinkStyle>
            <LinkStyle>Map</LinkStyle>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h4">ABOUT</Typography>
            <LinkStyle>Whitepaper</LinkStyle>
            <LinkStyle>FAQ</LinkStyle>
            <LinkStyle>Blog</LinkStyle>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h4">LINKS</Typography>
            <LinkStyle>OpenSea</LinkStyle>
            <LinkStyle href="https://www.infinity-global.app/1" target="_blank">
              Infinity Global
            </LinkStyle>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            sx={{ width: 300 }}
          >
            <IconButton
              sx={{ width: 50, height: 50 }}
              href="https://discord.com/invite/YQrsZtHc3F"
              target="_blank"
            >
              <Icon icon={discord} color="white" />
            </IconButton>
            <IconButton
              sx={{ width: 50, height: 50 }}
              href="https://medium.com/@infinityglobalapp"
              target="_blank"
            >
              <Icon icon={medium} color="white" />
            </IconButton>
            <IconButton
              sx={{ width: 50, height: 50 }}
              href="https://t.me/joinchat/wtpcV3GJC2U3NWFh"
              target="_blank"
            >
              <Icon icon={telegram} color="white" />
            </IconButton>
            <IconButton
              sx={{ width: 50, height: 50 }}
              href="https://twitter.com/InfinityGloball"
              target="_blank"
            >
              <Icon icon={twitter} color="white" />
            </IconButton>
            <IconButton
              sx={{ width: 50, height: 50 }}
              href="https://youtube.com/channel/UC8493vxxKgx3JX7Cj1-JJGw"
              target="_blank"
            >
              <Icon icon={youtube} color="white" />
            </IconButton>
            <IconButton
              sx={{ width: 50, height: 50 }}
              href="https://www.facebook.com/205417191509270/posts/215019870549002/"
              target="_blank"
            >
              <Icon icon={facebook} color="white" />
            </IconButton>
            <IconButton
              sx={{ width: 50, height: 50 }}
              href="https://join.skype.com/invite/A8BwTI46xyZL"
              target="_blank"
            >
              <Icon icon={skype} color="white" />
            </IconButton>
            <IconButton
              sx={{ width: 50, height: 50 }}
              href="https://www.linkedin.com/in/luciano-baruk-b3891119b"
              target="_blank"
            >
              <Icon icon={linkedin} color="white" />
            </IconButton>
            <IconButton
              sx={{ width: 50, height: 50 }}
              href="https://github.com/infinity-global"
              target="_blank"
            >
              <Icon icon={github} color="white" />
            </IconButton>
            <IconButton
              sx={{ width: 50, height: 50 }}
              href="https://opensea.io/collection/infinitynftgamesmeta"
              target="_blank"
            >
              <Box
                component="img"
                src="/images/opensea.svg"
                sx={{ width: 30 }}
              />
            </IconButton>
            <IconButton
              sx={{ width: 50, height: 50 }}
              href="https://info.quickswap.exchange/#/token/0x323a17aa6c92ff9f07dfc2a0701e3323775061cf"
              target="_blank"
            >
              <Box component="img" src="/images/token.png" sx={{ width: 30 }} />
            </IconButton>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
}
