import { useState, useEffect } from "react";
import * as THREE from "three";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import 'index.css';
import Lands from 'components/land_data';

import {
  Container,
  Box,
  Stack,
  TextField,
  Button,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useICOContract } from "hooks/useContract";
import { getErrorMessage } from "utils/getErrorMessage";
import { parseEther } from "ethers/lib/utils";
import { useSnackbar } from "notistack";
import { ethers } from "ethers";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  marginTop: 20,
  padding: "10px 20px",
  backgroundColor: "#161936",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  background: "#1c214d",
  padding: theme.spacing(2),
  padding: "60px",
}));

export default function Homepage() {
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //Freelancer.com's C Scott's* code. You may use this code and alter it. You don't need to give me any attribution.
  //*https://www.freelancer.com/u/ScottContina

  //begin C Scott code
  useEffect(() => {
    //this is an object of HTML elements pertaining to the land menu. this object is here so we can easily change the land menu
    const phy_land = {
      'body': document.getElementById('menu_style'),
      'image': document.getElementById('land_image'),
      'location': document.getElementById('land_location'),
      'size': document.getElementById('land_size'),
      'token': document.getElementById('land_token'),
      'owner': document.getElementById('land_owner'),
      'name': document.getElementById('land_name'),
      'description': document.getElementById('land_description'),
      'link': document.getElementById('land_link')
    };

    //Three.js caching
    THREE.Cache.enabled = true;

    //add a lot of the Three.js things. scene, camera, renderer, etc.
    const menu = document.getElementById('menu_style');
    const canvas = document.getElementById('3d_objects');
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 50 );
    const renderer = new THREE.WebGLRenderer({ canvas: canvas });
    const pointLight = new THREE.PointLight( 0xffffff, 1 );
    const raycaster = new THREE.Raycaster();
    const pointer = new THREE.Vector2();

    //fix up the aspect of the camera so that it doesn't stretch
    camera.aspect = canvas.width / canvas.height;
    camera.updateProjectionMatrix();

    //this is the scale of the hit object pieces for that huge 3d map
    const lightscale = .5001;

    //this will be an array. this is an array of the last object lit when a user selected something on the map
    var last_material = null;

    //this is the clear material
    const clear_mat = new THREE.MeshPhongMaterial({
      color: 0xFFFFFF,
      flatShading: true,
      visible: false
    });
    //this is the highlighted material
    const glow_mat = new THREE.MeshPhongMaterial({
      color: 0xFFFF00,    // red (can also use a CSS color string here)
      flatShading: true,
    });
    //this is the debug highlighted material
    const glow_mat_debug = new THREE.MeshPhongMaterial({
      color: 0x000000,    // red (can also use a CSS color string here)
      flatShading: true,
    });

    //set the width, height, and background color
    renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.setClearColor( 0xffffff, 0);

    //attach the point light to the camera for a desired effect
    camera.add(pointLight);
    scene.add(camera);
    pointLight.position.set(0,0,0);

    //set camera z position. that is how close it is to the sphere
    camera.position.z = 50;

    //add a few listeners for click/tap events for the raycaster
    window.addEventListener( 'pointermove', onPointerMove );
    window.addEventListener( 'pointerdown', onPointerDown );
    window.addEventListener( 'pointerup', onPointerUp );

    //add a resize event for when the window resize
    window.addEventListener( 'resize', resize );

    //add a click listener to close the menu
    document.getElementById( 'menu_x' ).addEventListener( 'click', remove_land_menu );

    //add freakin  mouse over and mouse out listeners to the menu so the freakin  orbit control will chill out when hovering over the menu
    menu.addEventListener( 'mouseover', mouse_over );
    menu.addEventListener( 'mouseout', mouse_out );

    //our helper arrays for click groups. yay!
    var raycaster_objects = [];
    var click_group = [];
    var clicker = [];
    //our helper integer for getting the progress of a click/tap
    var pointing = 0;
    //our freakin helper boolean for the mouse to tell when the mouse is over the menu or not
    var over_menu = false;

    //alter some of the camera controls properties
    const controls = new OrbitControls( camera, canvas );
    //controls.enableDamping = true;
    //controls.dampingFactor = 0.5; //honestly, the damping looks nasty, so i'm keeping it out.
    controls.zoomSpeed = .5; //all three of these lines are self-explanatory
    controls.minDistance = 46.3;
    controls.maxDistance = 75;
    controls.update();

    //animate the 3d stuff
    animate();

    function animate() {
      requestAnimationFrame( animate ); //keep this in

      Raycast();

      if (pointing > 0) pointing++; //have control of our pointing status
      
      controls.update(); //keep this in
      
      renderer.render( scene, camera ); //and keep this in
    }
    function Raycast() {
      //the function to cast rays from the pointer viewed through the camera

      if (pointing != 1) return; //this line of code makes sure this function runs soon as the click begins
      if (over_menu === true) return; //this line of code makes sure this freakin function doesn't run when the mouse is over the freakin menu

      //now cast a ray
      raycaster.setFromCamera( pointer, camera );
      const intersects = raycaster.intersectObjects( raycaster_objects );
      
      var num = 0; //the land number
      var n = 1; //1 means we are mapping lands in the console, 0 means we are not mapping lands

      //if there was no hits, remove the menu. the user clicked in the void
      if (intersects.length < 1) remove_land_menu();

      //loop through all things that this raycast hit. i am breaking it after the first hit though. probably don't need a for loop for this...
      for ( let i = 0; i < intersects.length; i ++ ) {
        //unlight the last lit group
        if (last_material != null) {
          for (var d in last_material) {
            last_material[d].material = clear_mat;
          }
        }

        //light this for spaces that don't have data or for debug purposes
        intersects[ i ].object.material = glow_mat_debug;

        //log information every time the user clicks the upper left of a land, so you can fill in the data in the "public/lands_data.js" file. set n to 0, 1, 2, 3, or 6
        if (n > 0) {
          num = parseInt(intersects[i].object.name);

          //temporary variables for ease of use
          var dim = '';
          
          //get the location info
          if (num < 200000) dim = ((num-100000-1)%121+1+121)+","+(Math.ceil((num-100000)/121)+121*2); //1. top
          else if (num < 300000) dim = ((num-200000-1)%121+1+121)+","+Math.ceil((num-200000)/121); //2. forward
          else if (num < 400000) dim = ((num-300000-1)%121+1+121)+","+(Math.ceil((num-300000)/121)+121); //3. bottom
          else if (num < 500000) dim = ((num-400000-1)%121+1+121*3)+","+Math.ceil((num-400000)/121); //4. back
          else if (num < 600000) dim = ((num-500000-1)%121+1)+","+Math.ceil((num-500000)/121); //5. left
          else if (num < 700000) dim = ((num-600000-1)%121+1+121*2)+","+Math.ceil((num-600000)/121); //6. right
          
          //log the info so you can copy it from the console log
          console.log(dim+'\n"'+num.toString()+'"');
        }
        
        //highlight all other spaces that belong in this land group when clicking a single space
        if (click_group[intersects[i].object.name] != undefined) {
          show_land_menu(click_group[intersects[i].object.name].info); //show the menu with this group's information
          for (var d in click_group[intersects[i].object.name]) {
            click_group[intersects[i].object.name][d].material = glow_mat;
          }
          last_material = click_group[intersects[i].object.name];
        }
        //nothing happens when the space you click is not the land_data.js file, so only the logo shows up with the dimension data
        else {
          show_land_menu(null);
          phy_land.location.innerHTML = dim;
          phy_land.size.innerHTML = "1x1";
          last_material = [intersects[i].object];
        }

        break; //break this sucka!!
      }
    }
    //show the selected element inside the menu
    function show_land_menu(elmt) {
      if (elmt == null) { //the elmt was set to null. this is an empty space
        phy_land.location.innerHTML = "";
        phy_land.size.innerHTML = "";
        phy_land.token.innerHTML = "";
        phy_land.owner.innerHTML = "";
        phy_land.name.innerHTML = "";
        phy_land.description.innerHTML = "";
        phy_land.link.innerHTML = "";
        phy_land.image.style.backgroundImage = "url(/images/logo.png)";
      }
      else { //the elmt is not null. this space has data filled in it
        //console.log(elmt);
        phy_land.location.innerHTML = elmt.location;
        phy_land.size.innerHTML = elmt.size;
        phy_land.token.innerHTML = elmt.token;
        phy_land.owner.innerHTML = elmt.owner;
        phy_land.name.innerHTML = elmt.name;
        phy_land.description.innerHTML = elmt.description;
        phy_land.link.innerHTML = "<a href='"+elmt.link+"'>"+elmt.link+"</a>";

        if (elmt.size < 4) phy_land.image.style.backgroundImage = "url(/images/land"+elmt.size+".png)";
        else phy_land.image.style.backgroundImage = "url(/images/land4.png)"; //for land 6x6, the image name is land4.png
      }

      //show the menu
      phy_land.body.style.display = "";

      //alter the 3d canvas width
      canvas.style.width = "65%";
      canvas.width = canvas.getBoundingClientRect().width;

      //update the camera, renderer, and controls
      camera.aspect = canvas.width / canvas.height;
      camera.updateProjectionMatrix();

      renderer.setSize(canvas.width, canvas.height);
      controls.update();
    }
    function remove_land_menu() {
      //update the canvas width
      canvas.style.width = "100%";
      canvas.width = canvas.getBoundingClientRect().width;

      //update camera, renderer, and controls
      camera.aspect = canvas.width / canvas.height;
      camera.updateProjectionMatrix();

      renderer.setSize(canvas.width, canvas.height);
      controls.update();

      //hide the menu
      phy_land.body.style.display = "none";

      over_menu = false; //oh, and don't forget to set the freakin over_menu to false because the menu is gone now
    }
    function onPointerMove( event ) {
      //update the pointer/mouse position
      pointer.x = ( (event.clientX -renderer.domElement.offsetLeft + window.scrollX) / renderer.domElement.width ) * 2 - 1;
      pointer.y = -( (event.clientY - renderer.domElement.offsetTop + window.scrollY) / renderer.domElement.height ) * 2 + 1;
    }
    function onPointerDown( event ) {
      //run this when the user left clicks
      if (event.button == 0) pointing = 1;
    }
    function onPointerUp( event ) {
      //run this when the user lets go of the left click
      if (event.button == 0) pointing = 0;
    }

    function resize() {
      //the resize function. change the size of the canvas based on whether the menu is up or down and update the camera, controls, and renderer like before
      
      if (phy_land.body.style.display == "none") {
        canvas.style.width = "65%";
      }
      else {
        canvas.style.width = "100%";
      }

      canvas.style.height = "100%";
      canvas.width = canvas.getBoundingClientRect().width;
      canvas.height = canvas.getBoundingClientRect().height;

      camera.aspect = canvas.width / canvas.height;
      camera.updateProjectionMatrix();

      renderer.setSize(canvas.width, canvas.height);
      controls.update();
    }

    //gotta add in these functions so the orbit controls don't interfere with the menu
    function mouse_over() {
      over_menu = true; //freakin OVER
    }
    function mouse_out() {
      over_menu = false; //and freakin OUT
    }

    remove_land_menu(); //start the page with the menu hidden

    //load the glb model
    const loader = new GLTFLoader();
    loader.load(
      'sphere.glb',
      function ( gltf ) {
        gltf.scene.scale.set(.5, .5, .5); 
        const root = gltf.scene;

        scene.add( root );
      },
      function ( xhr ) {
        console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
      },
      function ( error ) {
        console.log( 'An error happened' );
      }
    );

    LoadOBJ();

    function LoadOBJ() {
      //load the obj file i put in 6 times. these will be used as raycast hit objects to detect user input.
      //glb file doesn't support squares as of 11/03/2022

      //not only do these load the files, but they also put a reference of each individual object inside an associative array.
      //after the last obj object loads, the associative arrays will be filled according to the group they belong to.
      //i don't want to keep looping through the land_data.js every time a user clicks

      const loaderobj1 = new OBJLoader();
      const loaderobj2 = new OBJLoader();
      const loaderobj3 = new OBJLoader();
      const loaderobj4 = new OBJLoader();
      const loaderobj5 = new OBJLoader();
      const loaderobj6 = new OBJLoader();

      //load selecting regions
      //fun fact: it took more than a day for my laptop to process and make this sphere piece with the squares in a usable order. typed a little Python in Blender and ran it
      loaderobj1.load( //top
        'sphere_piece.obj',
        function ( object ) {
          object.scale.set(lightscale, lightscale, lightscale);
          var g = "";
          var n = 1;
          for (var j = 0; j < object.children.length; j++) {
            object.children[j].material = clear_mat;
            if (n < 10) g = "10000"+n;
            else if (n < 100) g = "1000"+n;
            else if (n < 1000) g = "100"+n;
            else if (n < 10000) g = "10"+n;
            else g = "1"+n;
            object.children[j].name = g;
            clicker[g] = object.children[j];
            n++;
          }
          scene.add( object );
          raycaster_objects.push(object);
        },
        function ( xhr ) { console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' ); },
        function ( error ) { console.log( 'An error happened' ); }
      );
      loaderobj2.load( //front
        'sphere_piece.obj',
        function ( object ) {
          object.scale.set(lightscale, lightscale, lightscale);
          object.rotateX(90 * Math.PI/180);
          var g = "";
          var n = 1;
          for (var j = 0; j < object.children.length; j++) {
            object.children[j].material = clear_mat;
            if (n < 10) g = "20000"+n;
            else if (n < 100) g = "2000"+n;
            else if (n < 1000) g = "200"+n;
            else if (n < 10000) g = "20"+n;
            else g = "2"+n;
            object.children[j].name = g;
            clicker[g] = object.children[j];
            n++;
          }
          scene.add( object );
          raycaster_objects.push(object);
        },
        function ( xhr ) { console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' ); },
        function ( error ) { console.log( 'An error happened' ); }
      );
      loaderobj3.load( //bottom
        'sphere_piece.obj',
        function ( object ) {
          object.scale.set(lightscale, lightscale, lightscale);
          object.rotateX(180 * Math.PI/180);
          var g = "";
          var n = 1;
          for (var j = 0; j < object.children.length; j++) {
            object.children[j].material = clear_mat;
            if (n < 10) g = "30000"+n;
            else if (n < 100) g = "3000"+n;
            else if (n < 1000) g = "300"+n;
            else if (n < 10000) g = "30"+n;
            else g = "3"+n;
            object.children[j].name = g;
            clicker[g] = object.children[j];
            n++;
          }
          scene.add( object );
          raycaster_objects.push(object);
        },
        function ( xhr ) { console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' ); },
        function ( error ) { console.log( 'An error happened' ); }
      );
      loaderobj4.load( //back
        'sphere_piece.obj',
        function ( object ) {
          object.scale.set(lightscale, lightscale, lightscale);
          object.rotateX(90 * Math.PI/180);
          object.rotateZ(-180 * Math.PI/180);
          var g = "";
          var n = 1;
          for (var j = 0; j < object.children.length; j++) {
            object.children[j].material = clear_mat;
            if (n < 10) g = "40000"+n;
            else if (n < 100) g = "4000"+n;
            else if (n < 1000) g = "400"+n;
            else if (n < 10000) g = "40"+n;
            else g = "4"+n;
            object.children[j].name = g;
            clicker[g] = object.children[j];
            n++;
          }
          scene.add( object );
          raycaster_objects.push(object);
        },
        function ( xhr ) { console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' ); },
        function ( error ) { console.log( 'An error happened' ); }
      );
      loaderobj5.load( //left
        'sphere_piece.obj',
        function ( object ) {
          object.scale.set(lightscale, lightscale, lightscale);
          object.rotateX(90 * Math.PI/180);
          object.rotateZ(90 * Math.PI/180);
          var g = "";
          var n = 1;
          for (var j = 0; j < object.children.length; j++) {
            object.children[j].material = clear_mat;
            if (n < 10) g = "50000"+n;
            else if (n < 100) g = "5000"+n;
            else if (n < 1000) g = "500"+n;
            else if (n < 10000) g = "50"+n;
            else g = "5"+n;
            object.children[j].name = g;
            clicker[g] = object.children[j];
            n++;
          }
          scene.add( object );
          raycaster_objects.push(object);
        },
        function ( xhr ) { console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' ); },
        function ( error ) { console.log( 'An error happened' ); }
      );
      loaderobj6.load( //right
        'sphere_piece.obj',
        function ( object ) {
          object.scale.set(lightscale, lightscale, lightscale);
          object.rotateX(90 * Math.PI/180);
          object.rotateZ(-90 * Math.PI/180);
          var g = "";
          var n = 1;
          for (var j = 0; j < object.children.length; j++) {
            object.children[j].material = clear_mat;
            if (n < 10) g = "60000"+n;
            else if (n < 100) g = "6000"+n;
            else if (n < 1000) g = "600"+n;
            else if (n < 10000) g = "60"+n;
            else g = "6"+n;
            object.children[j].name = g;
            clicker[g] = object.children[j];
            n++;
          }
          scene.add( object );
          raycaster_objects.push(object);

          //there! the array is now filled with every single square with a usable name
          //for each click object, search through the land_data and add its siblings (basically the group of spaces they are found in)
          for (var c in clicker) {
            for (var l in Lands) {
              if (Lands[l].spaces.indexOf(c) > -1) {
                var s = [];

                for (var ls in Lands[l].spaces) {
                  s.push(clicker[Lands[l].spaces[ls]]);
                }
                
                click_group[c] = s;
                click_group[c].info = Lands[l];
                break; //got the one. break it and continue running through 'clicker'
              }
            }
          }
        },
        function ( xhr ) { console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' ); },
        function ( error ) { console.log( 'An error happened' ); }
      );
    }
  }, []);

  //here are some styles i made up
  const menu_style = `
  #map_bg {position:absolute; top:0px; width:100%; height:100%;}
  #3d_objects {position:absolute; left:0px; top:0px; width:100%; height:100%; z-index:1;}
  #menu_style {position:absolute; right:0px; top:0px; width:35%; height:calc( 100% - 56px ); background-color:#110759; margin-top:56px; padding-bottom:20px; overflow-y:auto; z-index:2;}
  .menu_left {font-size:1.5vw; display:inline-block;}
  .menu_right {font-size:1.3vw; display:inline-block; color:#85f5ff;}
  .demoWrapper {
    
  }
  #land_image {
    width: 100%;
    padding-bottom: 100%;
    background-image: url(/images/land1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #1a1a1a;
  }
  .menu_sub_menu {position:relative; width:100%; display:inline-block; margin-bottom:17px;}
  .menu_float_left {position:relative; width:40%; float:left; padding-left:0.55vw;}
  .menu_float_right {position:relative; width:60%; float:right; text-align:right; padding-right:0.55vw;}
  .menu_float_under {position:relative; width:100%; margin-top:3px; font-size:1.5vw; padding-left:0.55vw; padding-right:0.55vw; display:inline-block; overflow-wrap:anywhere;}
  .menu_button_container {text-align: center;}
  #menu_button {
    background-color: #0D67FE;
    border: 0;
    min-width: 50%;
    padding: 12px 16px;
    color: white;
    font-weight: bold;
    font-size: 1.6vw;
    border-radius: 2px;
    cursor: pointer;
  }
  #menu_x {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40px;
    height: 40px;
    border: 0;
    background-color: transparent;
    font-size: 2vw;
    font-weight: bold;
    color: #4a4a4a;
    cursor: pointer;
  }
`;
  //end majority of Freelaner.com's C Scott code. more is below where it looks like someone was not so familiar with MUI or JSX and just used vanilla HTML instead. that was me.

  return (
    <Box>
      <Stack sx={{ position: "relative", height: "100vh", overflow: "hidden" }}>
        <Stack
            alignItems="flex-start"
            justifyContent="center"
            spacing={5}
            sx={{ position: "absolute", width: 1, height: 1 }}
          >
          <style>{menu_style}></style>
          <div id="map_bg">
            <canvas id="3d_objects"></canvas>
            <div id="menu_style">
              <button id="menu_x">X</button>
              <div class="demoWrapper"><img id='land_image' /></div>
              <div>
                <div class="menu_sub_menu" style={{marginTop: "10px"}}>
                  <div class="menu_float_left"><span class="menu_left">Location:</span></div>
                  <div class="menu_float_right"><span id="land_location" class="menu_right">1,1</span></div>
                </div>
                <div class="menu_sub_menu">
                  <div class="menu_float_left"><span class="menu_left">Size:</span></div>
                  <div class="menu_float_right"><span id="land_size" class="menu_right">1x1</span></div>
                </div>
                <div class="menu_sub_menu">
                  <div class="menu_float_left"><span class="menu_left">Token ID:</span></div>
                  <div class="menu_float_under"><span id="land_token">0x803c698f680e702609fb09F7782bEA2Ccd3CbdA0</span></div>
                </div>
                <div class="menu_sub_menu">
                  <div class="menu_float_left"><span class="menu_left">Owner:</span></div>
                  <div class="menu_float_under"><span id="land_owner">0x803c698f680e702609fb09F7782bEA2Ccd3CbdA0</span></div>
                </div>
                <div class="menu_sub_menu">
                  <div class="menu_float_left"><span class="menu_left">Name:</span></div>
                  <div class="menu_float_right"><span id="land_name">Roger</span></div>
                </div>
                <div class="menu_sub_menu">
                  <div class="menu_float_left"><span class="menu_left">Description:</span></div>
                  <div class="menu_float_under"><span id="land_description">This is some arbitrary description that will later be filled out.</span></div>
                </div>
                <div class="menu_sub_menu">
                  <div class="menu_float_left"><span class="menu_left">Link:</span></div>
                  <div class="menu_float_under"><span id="land_link">https://www.somearbitrarylink.com</span></div>
                </div>
              </div>
              <div class="menu_button_container">
                <button id="menu_button">Show in OpenSea</button>
              </div>
            </div>
          </div>
        </Stack>
      </Stack>
    </Box>
  );
}