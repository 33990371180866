import { useState } from "react";
import {
  Button,
  Box,
  Stack,
  Typography,
  Hidden,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useWeb3React } from "@web3-react/core";
import useAuth from "hooks/useAuth";
import {
  connectors,
  connectorLocalStorageKey,
  walletLocalStorageKey,
} from "./config";
import { ConnectorNames } from "utils/web3React";
import { ethers } from "ethers";

// export function BusdBalance() {
//   const { account } = useEthers();
//   const busdBalanceBigNumber = useTokenBalance(BusdAddress, account);
//   const busdBalance =
//     busdBalanceBigNumber && ethers.utils.formatUnits(busdBalanceBigNumber, 18);
//   return busdBalance;
// }
const CHAINS = [
  { id: "eth", name: "Ethereum" },
  { id: "bsc", name: "Binance Smart Chain" },
  { id: "polygon", name: "Polygon" },
  { id: "avalanche", name: "Avalanche" },
  { id: "optimism", name: "Optimism" },
  { id: "fantom", name: "Fantom" },
];
export default function ConnectModal({ login, open, onClose }) {
  const { account } = useWeb3React();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          bgcolor: "#252b45",
          borderRadius: 0,
          width: 600,
          backgroundImage: "none",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 3, pb: 15 }}>
        <Stack direction="row" justifyContent="center" flexWrap="wrap">
          <Typography variant="h3" sx={{ mt: 5 }}>
            Link Wallet
          </Typography>
          <Typography fontSize={18} align="center">
            Choose at least one Blockchain of the wallet that you want to add to
            your Multi-wallet.
          </Typography>
          <Stack spacing={2} sx={{ width: 1, px: 10, mt: 4 }}>
            {CHAINS.map((item, index) => (
              <Stack
                onClick={() => {
                  login(ConnectorNames.Injected, item.id);
                  localStorage.setItem(walletLocalStorageKey, "Metamask");
                  localStorage.setItem(
                    connectorLocalStorageKey,
                    ConnectorNames.Injected
                  );
                  onClose();
                }}
                direction="row"
                alignItems="center"
                spacing={3}
                sx={{
                  cursor: "pointer",
                  width: 1,
                  borderRadius: "2px",
                  border: "1px solid #ffffff50",
                  px: 2,
                  py: 1,
                }}
              >
                <Box
                  component="img"
                  src={`/images/chains/${item.id}.png`}
                  sx={{ width: 32 }}
                />
                <Typography>{item.name}</Typography>
              </Stack>
            ))}
          </Stack>

          {/* <Box component="img" src={wallet.icon} sx={{ width: 40 }} /> */}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
