import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import {
  Container,
  Box,
  Stack,
  TextField,
  Button,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useICOContract } from "hooks/useContract";
import { getErrorMessage } from "utils/getErrorMessage";
import { parseEther } from "ethers/lib/utils";
import { useSnackbar } from "notistack";
import { ethers } from "ethers";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
const FAQS = [
  {
    category: "Infinity World Overview",
    faqs: [
      {
        title: "What is Infinity World?",
        text: "Infinity World is an open world that enables users to build 3D immersive applications on top of several blockchains. In Infinity World, users can take advantage of traditional 3D open-world features such as building 3D architectures, hosting virtual meetings, exhibiting NFTs, and more advanced functionality such as creating their own 3D decentralized applications (DApps) using Infinity's built-in computational resources.",
      },
      {
        title: "When will Infinity World release?",
        text: "Infinity World is currently in the pre-alpha version. We are now constructing public tools，services, and buildings such as universities and headquarters for famous companies. This will make our metaverse world energetic and provide fully functional support for the community. We set up joint laboratories with different partners and help them get connected to the decentralized world. We plan to open up alpha testing of Infinity World to the community in 6 months, with more features and creator-friendly metaverse environment betas coming in 2023. In 2022 Q2, we will open the alpha version of Infinity World, with a fundamental fast build toolbox and low code development platform to help the users build the metaverse world. We will also present public service buildings for practical use, such as education, tourism, and social connections. The beta version is coming in 2023, and we will do the official opening in early 2024.",
      },
      {
        title:
          "Which platform should I choose to participate in Infinity World?",
        text: "Currently, we don't support mobile devices. Users can run Infinity World from a PC or Mac running Chrome or Firefox. While it may be technically possible that other browsers might work, to ensure optimal performance, we highly recommend Firebox or Chrome.",
      },
      {
        title:
          "Why should I participate in Infinity World? What are some highlights in Infinity World?",
        text: "1. Infinity World is the first open virtual world project simultaneously running on different blockchain networks. The system now supports Ethereum and Flow, and we plan to include more blockchain networks as its persistent and consensus layer. 2. The Infinity team has extensive experience with blockchain games, middleware, and the metaverse. Cell Evolution won an award worth millions successfully helped us establish closer connections with the world's first developer ecosystems of the blockchain games. We collaborate with ChainIDE, a cloud-based integrated development environment that provides online services in more than 165 countries. The Metaverse Bootcamp that we co-hosted incubated and escorted over 30 blockchain games and metaverse projects. 3. We feel proud of the global developer ecosystem and partnership we have developed. Our official partnership with the Flow team makes Infinity World the first metaverse project in the Flow ecosystem. Furthermore, the Flow team plan to build a Flow headquarter in Infinity World. Flow blockchain projects like CryptoKitties and NBA Top Shots will collaborate with Infinity World gradually.",
      },
    ],
  },
  {
    category: "Infinity Land",
    faqs: [
      {
        title: "What is Infinity Land?",
        text: "Infinity Land is the smallest territorial unit in Infinity World with finite computation and storage resources. A Land is also represented on several blockchains as a tradable NFT token. For instance, it will be an ERC-721 token on Ethereum, whereas on Flow will be an on-chain Flow resource.",
      },
      {
        title: "What is Land Voucher?",
        text: "A Land Voucher is a certificate Non-Fungible Token (NFT) that proves your ownership of a square of Lands in the early stages of Infinity World. It can be freely traded in marketplaces in order to transfer ownership.",
      },
      {
        title: "How large is a tile of Infinity Land?",
        text: "A Land is 30m x 30m, or 99ft x 99ft, and there will be a height limitation which we are still deciding. However, if you are our partners or community members, you can apply extra height for special usages.",
      },
      {
        title: "Can I merge or split Infinity Land?",
        text: "If owners buy more than one Land, they can split it and sell it in parts, When a group of Lands shares the same owner, the owner can choose to merge the Lands into a Space for larger construction and more complicated application development. The computational resources and storage of the Lands will get merged too.",
      },
      {
        title: "What can Land owners do?",
        text: "The Infinity Lands are tradable and transferable via blockchain networks, and their owners retain complete control over the creations on their Lands in Infinity World. Users can take advantage of traditional 3D open-world features such as building 3D architectures, hosting virtual meetings, exhibiting NFTs, and more advanced functionality such as creating their own 3D decentralized Applications (DApps) using Infinity's built-in computational resources. These DApps include 3D games and 3D marketplace, among others.",
      },
      {
        title: "Can I resell my Land in the pre-alpha phase?",
        text: "Yes. On both blockchain networks, Ethereum and Flow, Infinity Lands are tradable and transferable even during the pre-alpha phase.",
      },
    ],
  },
  {
    category: "Community Pre-sale",
    faqs: [
      {
        title: "What is the community Land pre-sale?",
        text: "The community Land pre-sale is a special event in which up to 5,000 Infinity Lands on Ethereum and 5,000 Infinity Lands on Flow ecosystem will be made available for early members to purchase. The purpose of this sale is to gather the early community for Infinity World. We want to gather the power from the community to help us accelerate the whole process. Coders, artists, scientists, geeks, and metaverse builders are welcome to join us. The sale will be a case-by-case application. Candidates will have to fill out a form explaining your plan on your early access Lands. Due to the limited supply, you might not be able to get all the Lands and Spaces you requested on your form.",
      },
      {
        title: "How will the community pre-sale work?",
        text: "To be eligible to join in Infinity World community pre-sale, you have to fill in the application form. If you are selected to be the first group of Infinity World builders, you will be added to Infinity's whitelist and receive an email to inform you that you are eligible to buy Lands on Infinity World's website. There will be several rounds of selections, and each qualified applicant will have 72 hours to finish the purchase in order to get your Land Vouchers. This is to avoid gas war and offer more flexibility to finish the transactions. The form will contain some contact info and features a couple of questions designed to show us your understanding of metaverse and the blockchain world. The application will start from Oct 8th to Nov 8th at 00:00 Pacific Time. We will evaluate all the submissions and give offers in several rounds. The earlier applications that come in are likely to get earlier offers.",
      },
      {
        title: "How much will pre-sale Land cost?",
        text: "Each Land’s price on Ethereum is 0.2 Ether. And for Flow users, the price is the same, but the payment is via FUSD or FLOW at the community pre-sale stage.",
      },
      {
        title: "What are some activities I can participate in pre-sale period?",
        text: "Referral Contest: The top 200 players with the most referred registrations will be rewarded as one pre-released Land in the Infinity World.",
      },
      {
        title: "How to get NFT Airdrop in early access?",
        text: "In order to make the Infinity World a better place, we are intended to synthesize the world with multi-elements. Therefore we will offer special NFT airdrops for each player in the Infinity World. We will offer free NFT airdrops to the owner of several NFT collections who participated in the early access. Please check the detailed NFT collection list on the the Infinity NFT airdrop event webpage",
      },
      {
        title: "Which wallet should I use?",
        text: "On Ethereum, we support Metamask and all other wallets integrated with web3.js. On Flow, we support Blocto. If your application is approved, you will be emailed with clear instructions on how to pay for your Land.",
      },
    ],
  },
  {
    category: "Infinity Essentials",
    faqs: [
      {
        title: "Which blockchain ecosystems will Infinity support?",
        text: "One significant feature of Infinity World is multichain connectivity. Infinity World system will maintain identities across various blockchains. At the moment, we plan to support Ethereum and Flow, but we propose to integrate additional EVM-based and layer-2 blockchains in the near future. Creators can also connect Infinity Objects to external smart contracts running on multiple blockchain networks. For instance, a creator can create a 3D Uniswap ATM by crafting its appearance and defining the function to invoke Uniswap's swap function in Ethereum. Similarly, a creator can also construct a 3D video gallery featuring Flow's NBA Topshot moments.",
      },
      {
        title: "What is Space Virtual Machine?",
        text: "Everything inside Infinity World is programmable. All the objects in a Land are managed by a canonical virtual machine called Space Virtual Machine (SVM), which has its own set of characteristics, 3D appearance, and lifecycle. Meanwhile, users can define custom attributes and implement custom methods to modify these Infinity Objects' behavior. A complex 3D application is formed by a collection of several programmed/scripted Infinity Objects that can interact with each other. Authorized creators can simply program the actions of Objects using the Infinity Object and Scene Editor.",
      },
      {
        title: "What is Infinity Object?",
        text: "Infinity Object is the collective name of all the 3D programmable objects in the Infinity World. These objects' appearance, actions, and lifecycles are managed by the Space Virtual Machine (SVM). Infinity object is hierarchical, which means it can have nested child Objects attached to it.",
      },
      {
        title: "How will Infinity support DApps?",
        text: "Infinity World is a 3D DApp platform. Infinity network consists of a number of Space Virtual Machine (SVM), each of which is responsible for running a Scene on a specific Land/Space that offers various functionalities. Creators can make a 3D application solely on one SVM and create cross-SVM or multiscene applications. Infinity World will provide creators with development and automation tools to facilitate scene and DApp development. We hope that in the future, thousands of 3D applications will operate concurrently in Infinity World.",
      },
    ],
  },
  {
    category: "Others",
    faqs: [
      {
        title: "Where is the Infinity Team's HQ based?",
        text: "Our headquarter is based in Vancouver, BC. We also have offices and remote staff operating from all over the world.",
      },
      {
        title: "Who is the Infinity Team?",
        text: "The Infinity Team was founded in Vancouver in 2017 and now grows up to more than fifty staff worldwide. The Infinity Team has deep experience in the research and development of blockchain systems.",
      },
      {
        title:
          "Where can I contact the Infinity Team if I have further questions?",
        text: "Join our Discord or email us to ask any questions you want, and We will answer as soon as possible. Also, Our community members will help too.",
      },
      {
        title: "What is the Infinity Team's determination?",
        text: "We are long-term builders in blockchain and a decentralized world. We hope we can help more developers worldwide get into the metaverse and grow up with the community.",
      },
    ],
  },
];
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  padding: "10px 0px",
  paddingLeft: 0,
  backgroundColor: "#111325",
  borderTop: "1px solid #ffffff10",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  background: "#111325",
  paddingTop: "10px",
  paddingLeft: "0px",
  paddingBottom: 40,
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default function Homepage() {
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box>
      <Stack
        alignItems="flex-start"
        justifyContent="center"
        spacing={5}
        sx={{ width: 1, mt: 20 }}
      >
        <Container maxWidth="lg">
          <Stack justifyContent="flex-start" spacing={5} mb={5}>
            <Typography variant="h2">Frequently Asked Questions</Typography>
          </Stack>
          <Stack direction="row" alignItems="flex-start" spacing={5}>
            <Stack
              spacing={1}
              sx={{ bgcolor: "#1a1f34", borderRadius: 1, p: 3, width: 300 }}
            >
              {FAQS.map((item, index) => (
                <Typography
                  key={index}
                  variant="h5"
                  onClick={() => setValue(index)}
                  sx={{
                    cursor: "pointer",
                    color: value == index ? "primary.main" : "white",
                    "&:hover": { color: "primary.main" },
                  }}
                >
                  {item.category}
                </Typography>
              ))}
            </Stack>
            <Stack flex={1}>
              {FAQS.map((item, index) => (
                <TabPanel value={value} index={index}>
                  {item.faqs.map((faq, j) => (
                    <Accordion
                      expanded={expanded === `panel${j + 1}`}
                      onChange={handleChange(`panel${j + 1}`)}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                      >
                        <Typography variant="h5">{faq.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="h5" color="#808ab8">
                          {faq.text}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </TabPanel>
              ))}
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </Box>
  );
}
