import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import {
  Container,
  Box,
  Stack,
  TextField,
  Button,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useICOContract } from "hooks/useContract";
import { getErrorMessage } from "utils/getErrorMessage";
import { parseEther } from "ethers/lib/utils";
import { useSnackbar } from "notistack";
import { ethers } from "ethers";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import axios from "axios";
import Moment from "react-moment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { ToText } from "utils/medium";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  marginTop: 20,
  padding: "10px 20px",
  backgroundColor: "#161936",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  background: "#1c214d",
  padding: theme.spacing(2),
  padding: "60px",
}));

export default function Homepage() {
  const [expanded, setExpanded] = useState("panel1");
  const [articles, setArticles] = useState([]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(async () => {
    const res = await axios.get(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@infinityglobalapp"
    );

    setArticles(res.data.items);
  }, []);

  return (
    <Box>
      <Stack sx={{ position: "relative", height: "100vh", overflow: "hidden" }}>
        <video autoPlay muted loop width="100%">
          <source src="https://gateway.pinata.cloud/ipfs/QmeR6NHkTQqV1dDMx1unugQRaHoeTFjWTADi4KFkXbXCPt" />
        </video>

        <Stack
          alignItems="flex-start"
          justifyContent="center"
          spacing={5}
          sx={{ position: "absolute", width: 1, height: 1 }}
        >
          <Container maxWidth="lg">
            <Stack alignItems="flex-start" justifyContent="center" spacing={5}>
              <Typography variant="h2">Infinity Game</Typography>
              <Typography>A Programmable 3D Multichain Metaverse</Typography>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#0062ff",
                  borderRadius: "1px",
                  width: 200,
                  height: 50,
                }}
              >
                REGISTER
              </Button>
            </Stack>
          </Container>
        </Stack>
      </Stack>

      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Stack direction="row" alignItems="center" spacing={5}>
              <Typography variant="h4" color="#0060f9">
                1st WAVE
              </Typography>
              <Box sx={{ width: 2, height: 30, bgcolor: "#0060f9" }} />
              <Typography variant="h4">2000 lands on Ethereum</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land1.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    1x1
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">0.1 ETH</Typography>
                </Stack>
              </Stack>

              <Box sx={{ width: "1px", height: 100, bgcolor: "#162d6d" }} />

              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land2.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    2x2
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">0.5 ETH</Typography>
                </Stack>
              </Stack>

              <Box sx={{ width: "1px", height: 100, bgcolor: "#162d6d" }} />

              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land3.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    3x3
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">3 ETH</Typography>
                </Stack>
              </Stack>

              <Box sx={{ width: "1px", height: 100, bgcolor: "#162d6d" }} />

              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land4.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    6x6
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">7 ETH</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack alignItems="center">
              <Typography variant="h3" mt={5}>
                SOLD OUT
              </Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Stack direction="row" alignItems="center" spacing={5}>
              <Typography variant="h4" color="#0060f9">
                2nd WAVE
              </Typography>
              <Box sx={{ width: 2, height: 30, bgcolor: "#0060f9" }} />
              <Typography variant="h4">2000 lands on Ethereum</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land1.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    1x1
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">0.1 ETH</Typography>
                </Stack>
              </Stack>

              <Box sx={{ width: "1px", height: 100, bgcolor: "#162d6d" }} />

              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land2.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    2x2
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">0.5 ETH</Typography>
                </Stack>
              </Stack>

              <Box sx={{ width: "1px", height: 100, bgcolor: "#162d6d" }} />

              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land3.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    3x3
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">3 ETH</Typography>
                </Stack>
              </Stack>

              <Box sx={{ width: "1px", height: 100, bgcolor: "#162d6d" }} />

              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land4.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    6x6
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">7 ETH</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack alignItems="center">
              <Typography variant="h3" mt={5}>
                SOLD OUT
              </Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Stack direction="row" alignItems="center" spacing={5}>
              <Typography variant="h4" color="#0060f9">
                3rd WAVE
              </Typography>
              <Box sx={{ width: 2, height: 30, bgcolor: "#0060f9" }} />
              <Typography variant="h4">2000 lands on Ethereum</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land1.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    1x1
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">0.1 ETH</Typography>
                </Stack>
              </Stack>

              <Box sx={{ width: "1px", height: 100, bgcolor: "#162d6d" }} />

              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land2.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    2x2
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">0.5 ETH</Typography>
                </Stack>
              </Stack>

              <Box sx={{ width: "1px", height: 100, bgcolor: "#162d6d" }} />

              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land3.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    3x3
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">3 ETH</Typography>
                </Stack>
              </Stack>

              <Box sx={{ width: "1px", height: 100, bgcolor: "#162d6d" }} />

              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land4.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    6x6
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">7 ETH</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack alignItems="center">
              <Typography variant="h3" mt={5}>
                SOLD OUT
              </Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Stack direction="row" alignItems="center" spacing={5}>
              <Typography variant="h4" color="#0060f9">
                4th WAVE
              </Typography>
              <Box sx={{ width: 2, height: 30, bgcolor: "#0060f9" }} />
              <Typography variant="h4">2000 lands on Ethereum</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land1.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    1x1
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">0.1 ETH</Typography>
                </Stack>
              </Stack>

              <Box sx={{ width: "1px", height: 100, bgcolor: "#162d6d" }} />

              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land2.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    2x2
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">0.5 ETH</Typography>
                </Stack>
              </Stack>

              <Box sx={{ width: "1px", height: 100, bgcolor: "#162d6d" }} />

              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land3.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    3x3
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">3 ETH</Typography>
                </Stack>
              </Stack>

              <Box sx={{ width: "1px", height: 100, bgcolor: "#162d6d" }} />

              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land4.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    6x6
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">7 ETH</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack alignItems="center">
              <Typography variant="h3" mt={5}>
                SOLD OUT
              </Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Stack direction="row" alignItems="center" spacing={5}>
              <Typography variant="h4" color="#0060f9">
                5th WAVE
              </Typography>
              <Box sx={{ width: 2, height: 30, bgcolor: "#0060f9" }} />
              <Typography variant="h4">2000 lands on Ethereum</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land1.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    1x1
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">0.1 ETH</Typography>
                </Stack>
              </Stack>

              <Box sx={{ width: "1px", height: 100, bgcolor: "#162d6d" }} />

              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land2.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    2x2
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">0.5 ETH</Typography>
                </Stack>
              </Stack>

              <Box sx={{ width: "1px", height: 100, bgcolor: "#162d6d" }} />

              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land3.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    3x3
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">3 ETH</Typography>
                </Stack>
              </Stack>

              <Box sx={{ width: "1px", height: 100, bgcolor: "#162d6d" }} />

              <Stack direction="row" alignItems="center" spacing={3}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src="/images/land4.png"
                    sx={{ width: 100 }}
                  />
                  <Typography variant="h5" color="#767993">
                    6x6
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="h5" color="#4d5073">
                    0 / 560
                  </Typography>
                  <Typography variant="h6" color="#4d5073">
                    AVAILABLE
                  </Typography>
                  <Typography variant="h5">7 ETH</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack alignItems="center">
              <Typography variant="h3" mt={5}>
                SOLD OUT
              </Typography>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <Stack direction="row" alignItems="center" spacing={10}>
          <Stack flex={1}>
            <Typography variant="h3">Multichain Metaverse</Typography>
            <Typography color="#808ab8" fontSize={20}>
              Infinity World is a distinctive virtual world that will support
              different blockchain infrastructures. Players can explore the
              world with different on-chain identities.
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Box component="img" src="/images/intro1.png" />
          </Stack>
        </Stack>

        <Stack direction="row-reverse" alignItems="center" spacing={10}>
          <Stack flex={1}>
            <Typography variant="h3">Immersive 3D DApps</Typography>
            <Typography color="#808ab8" fontSize={20}>
              Creators can enrich and extend the world by building immersive 3D
              decentralized applications (DApps) that can connect to external
              services and applications. For example, players can visually trade
              and purchase NFTs through 3D DApps from OpenSea and other
              marketplaces.
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Box component="img" src="/images/intro2.png" />
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={10}>
          <Stack flex={1}>
            <Typography variant="h3">Programmable NFTs & Objects</Typography>
            <Typography color="#808ab8" fontSize={20}>
              All in-game objects and NFTs have their own lifecycles. Landowners
              can customize their behaviors, appearances, and transformations
              via Turing-complete programs.
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Box component="img" src="/images/intro3.png" />
          </Stack>
        </Stack>

        <Stack direction="row-reverse" alignItems="center" spacing={10}>
          <Stack flex={1}>
            <Typography variant="h3">Create With Automation</Typography>
            <Typography color="#808ab8" fontSize={20}>
              Import various NFTs on the blockchain and create in-game buildings
              and constructions with automated tools.
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Box component="img" src="/images/intro4.png" />
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={10}>
          <Stack flex={1}>
            <Typography variant="h3">Gameplay & Entertainment</Typography>
            <Typography color="#808ab8" fontSize={20}>
              Both public and user-created entertainment venues will be built in
              the virtual world, including 3D games, NFT galleries, and sports
              centers. Players can enjoy their second life here.
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Box component="img" src="/images/intro5.png" />
          </Stack>
        </Stack>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <Stack alignItems="center" spacing={5}>
          <Typography variant="h3">ROADMAP</Typography>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "transparent",
                border: "none",
                color: "#fff",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  transparent",
              }}
              // date="2011 - present"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              icon={
                <Box
                  component="img"
                  src="/images/token.png"
                  // sx={{ width: 30, height: 30 }}
                />
              }
            >
              <Typography color="primary" variant="h3">
                2023 Q4
              </Typography>
              <Typography variant="h4">Community Pre-sale</Typography>
              <Typography color="#808ab8">
                Oct 15th: 1st round (up to 2000 Lands on Ethereum) Oct 26th: 2nd
                round (up to 5000 Lands on Polygon) Nov 8th: 3rd round (up to
                3000 Lands on Ethereum)
              </Typography>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "transparent",
                border: "none",
                color: "#fff",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  transparent",
              }}
              // date="2011 - present"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              icon={
                <Box
                  component="img"
                  src="/images/token.png"
                  // sx={{ width: 30, height: 30 }}
                />
              }
            >
              <Typography color="primary" variant="h3">
                2024 Q1
              </Typography>
              <Typography variant="h4">Multichain Identity System</Typography>
              <Typography color="#808ab8">
                Support for Ethereum and Flow Accounts for Infinity Identity
                System Globalization and Targeting of Communities and Brands
              </Typography>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "transparent",
                border: "none",
                color: "#fff",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  transparent",
              }}
              // date="2011 - present"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              icon={
                <Box
                  component="img"
                  src="/images/token.png"
                  // sx={{ width: 30, height: 30 }}
                />
              }
            >
              <Typography color="primary" variant="h3">
                2024 Q2
              </Typography>
              <Typography variant="h4">
                Infinity World v1.0: A Traversable World
              </Typography>
              <Typography color="#808ab8">
                Release of A Traversable World with Core 3D DApps Space Virtual
                Machine SDK and API Closed Beta Testing
              </Typography>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "transparent",
                border: "none",
                color: "#fff",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  transparent",
              }}
              // date="2011 - present"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              icon={
                <Box
                  component="img"
                  src="/images/token.png"
                  // sx={{ width: 30, height: 30 }}
                />
              }
            >
              <Typography color="primary" variant="h3">
                2024 Q3
              </Typography>
              <Typography variant="h4">
                Infinity World Builder and Developer API & SDK
              </Typography>
              <Typography color="#808ab8">
                Builder API and SDK Release Coder API and SDK Release
              </Typography>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "transparent",
                border: "none",
                color: "#fff",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  transparent",
              }}
              // date="2011 - present"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              icon={
                <Box
                  component="img"
                  src="/images/token.png"
                  // sx={{ width: 30, height: 30 }}
                />
              }
            >
              <Typography color="primary" variant="h3">
                2024 Q4
              </Typography>
              <Typography variant="h4">
                Infinity World v2.0: A Programmable World
              </Typography>
              <Typography color="#808ab8">
                Object and Scene Editor & Low-Code Creation Tool Release
                Partnership and Collaboration with Builder Organizations
              </Typography>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "transparent",
                border: "none",
                color: "#fff",
                boxShadow: "none",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  transparent",
              }}
              // date="2011 - present"
              iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
              icon={
                <Box
                  component="img"
                  src="/images/token.png"
                  // sx={{ width: 30, height: 30 }}
                />
              }
            >
              <Typography color="primary" variant="h3">
                2025 Q1
              </Typography>
              <Typography variant="h4">
                Infinity World v3.0: A Infinite world
              </Typography>
              <Typography color="#808ab8">
                Programmable NFT Support Global Creator Grant Event Project
                Origin: AI-Generated Metaverse Release
              </Typography>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </Stack>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 20 }}>
        <Stack alignItems="center" spacing={5}>
          <Typography variant="h3">BLOGS</Typography>
          <Stack spacing={4}>
            {articles.length > 0 &&
              articles.map((item, index) => (
                <Stack direction="row" spacing={2}>
                  <Box
                    sx={{
                      borderRadius: 1,
                      width: 200,
                      height: 200,
                      backgroundImage: `url(${item.thumbnail})`,
                      backgroundPosition: "50%",
                      backgroundSize: "cover",
                      alignSelf: "center",
                    }}
                  />
                  <Stack
                    flex={1}
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <Typography variant="h4">{item.title}</Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography fontSize={20} color="#d3d4df">
                        <Moment format="MM-DD">{item.pubDate}</Moment>
                      </Typography>
                      <CalendarTodayIcon />
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: 20,
                        color: "#d3d4df",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                    >
                      {ToText(item.description.substring(0, 500))}
                    </Typography>
                    <Button
                      variant="contained"
                      href={item.link}
                      target="_blank"
                    >
                      LEARN MORE
                    </Button>
                  </Stack>
                </Stack>
              ))}
          </Stack>
        </Stack>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 20 }}>
        <Stack alignItems="center" spacing={5}>
          <Typography variant="h3">OUR COLLABORATORS</Typography>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            columnSpacing={5}
            rowSpacing={8}
          >
            {[...Array(15)].map((item, index) => (
              <Grid item md={2.4}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src={`/images/brand${index + 1}.png`}
                    sx={{ height: 40 }}
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Container>

      <Container maxWidth="lg" sx={{ mt: 30 }}>
        <Stack alignItems="center" spacing={5}>
          <Typography variant="h3">OUR INVESTORS</Typography>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            columnSpacing={5}
            rowSpacing={8}
          >
            {[...Array(17)].map((item, index) => (
              <Grid item md={2.4}>
                <Stack alignItems="center">
                  <Box
                    component="img"
                    src={`/images/inv${index + 1}.png`}
                    sx={{ height: 60 }}
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}
